<template>
	<div id="effe2-loader" v-show="display_modal === true">
		
		<img src="/assets/loader.gif" /><br/>
		Please wait
	</div>
</template>

<script>

import axios from 'axios';
import $ from 'jquery';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

export default {
	name: 'ConnectProject',
	components: {

	},
	props: {

	},
	data: function (){
		return {
			
			display_modal: true,
		}
	},
	mounted: function(){
	
		this.get_project();
	},
	methods: {
		
		get_project: function() {
		
			if(!this.$root.get_project_ready) {
				
				var $this = this;
				
				setTimeout(function() { $this.get_project(); }, 500);
				return;
			}
			
			var component = this;
		
			var domain = window.location.host;
		
			// get live raffle
			axios.post('https://raffle-back.effe2.xyz/raffle/get-customer-id', {url: domain}).then(function (result) {
				
				var project = result.data.project;
				
				$('#favicon')[0].href = project.logo;
				$('#favicon2')[0].href = project.logo;
				
				component.project = project;
				component.$root.project = project;
				
				if(project.theme == 'dark') {
					
					$('body').attr('data-theme', 'dark');
				}
				
				if(project.theme == 'custom') {
				
					$('body')[0].style.setProperty("--background", project.css_background);
					$('body')[0].style.setProperty("--background-primary", project.css_background_primary);
					$('body')[0].style.setProperty("--border-color", project.css_border_color);
					$('body')[0].style.setProperty("--color-nft-name", project.css_color_nft_name);
					$('body')[0].style.setProperty("--countdown-color", project.css_color_countdown);
					$('body')[0].style.setProperty("--box-shadow-header", project.css_box_shadow_header);
					$('body')[0].style.setProperty("--button-back-to-raffles", project.css_background_button_back_to_raffle);
					
					
					$('body')[0].style.setProperty("--button-primary-background", project.css_background_button_primary);
					$('body')[0].style.setProperty("--button-primary-background-hover", project.css_background_button_primary_hover);
					$('body')[0].style.setProperty("--button-primary-color", project.css_color_button_primary);
					$('body')[0].style.setProperty("--button-primary-color-hover", project.css_color_button_primary_hover);
					
					
					$('body')[0].style.setProperty("--button-secondary-background", project.css_background_button_secondary);
					$('body')[0].style.setProperty("--button-secondary-background-hover", project.css_background_button_secondary_hover);
					$('body')[0].style.setProperty("--button-secondary-color", project.css_color_button_secondary);
					$('body')[0].style.setProperty("--button-secondary-color-hover", project.css_color_button_secondary_hover);
					
					
				}
				
				component.$root.$emit('projectSelected');
				component.display_modal = false;
				
				
				
			});
		}
	}

}

</script>

